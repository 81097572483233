<template>
    <div class="dashboard">
        <div class="dashboard-inner text-left">
            <div class="container-fluid my-3">
                <h3 class="text-white mb-2">Dashboard</h3>
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="text-white font-weight-normal">Monitor your audiences</h5>
                    <div class="qr--ac d-flex align-items-center">
                        <button class="btn button-tr" @click="$router.push({ name : 'Report' })">Quiz Report</button>
                        <div class="ml-1 sorting-by">
                            <select @change="filterByDays" v-model="option_date" id="inputState" class="form-control">
                                <option value="7" selected>Last 7 days</option>
                                <option value="90">Last 90 days</option>
                                <option value="365">Last 365 days</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="analytics text-left text-white mt-3">
                <h3 class="font-weight-normal">Analytics</h3>
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-3">
                            <div class="card mr-3">
                                <div class="card-body p-1">
                                    <div class="my-2">
                                        <div class="d-flex justify-content-between mx-4">
                                            <p class="text-none mb-0">Contents</p>
                                            <p class="mb-0 text-purple cursor-pointer" @click="detailChart('1')">More</p>
                                        </div>
                                        <div class="d-flex align-items-center ml-4 mt-2">
                                            <h4 class="">{{ totalContent ? totalContent : 0 }}</h4>
                                            <img src="@/assets/images/icons/ic_dashboard_arrow.svg" alt="" class="ml-1">
                                        </div>
                                        <div v-if="loading == true" style="height:215px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <template v-if="loading == false">
                                            <apexchart type="area" height="200" :options="chartOptions" :series="seriesContent"></apexchart>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card mr-3">
                                <div class="card-body p-1">
                                    <div class="my-2">
                                        <div class="d-flex justify-content-between mx-4">
                                            <p class="text-none mb-0">Plays</p>
                                            <p class="mb-0 text-purple cursor-pointer" @click="detailChart('2')">More</p>
                                        </div>
                                        <div class="d-flex align-items-center ml-4 mt-2">
                                            <h4 class="">{{ totalPlay ? totalPlay : 0 }}</h4>
                                            <img src="@/assets/images/icons/ic_dashboard_arrow.svg" alt="" class="ml-1">
                                        </div>
                                        <div v-if="loading == true" style="height:215px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <template v-if="loading == false">
                                            <apexchart type="area" height="200" :options="chartOptions" :series="seriesPlay"></apexchart>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card mr-3">
                                <div class="card-body p-1">
                                    <div class="my-2">
                                        <div class="d-flex justify-content-between mx-4">
                                            <p class="text-none mb-0">Avg. Play Durations</p>
                                            <p class="mb-0 text-purple not-allowed">More</p>
                                        </div>
                                        <div class="d-flex align-items-center ml-4 mt-2">
                                            <h4 class="">0</h4>
                                            <img src="@/assets/images/icons/ic_dashboard_arrow.svg" alt="" class="ml-1">
                                        </div>
                                        <div v-if="loading == true" style="height:215px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else class="d-flex align-items-center justify-content-center" style="height:215px">
                                            <p>No Data Available</p>
                                        </div>
                                        <!-- <apexchart type="area" height="200" :options="chartOptions" :series="seriesPlay"></apexchart> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card mr-2">
                                <div class="card-body p-1">
                                    <div class="my-2">
                                        <div class="d-flex justify-content-between mx-4">
                                            <p class="text-none mb-0">Impressions</p>
                                            <p class="mb-0 text-purple not-allowed">More</p>
                                        </div>
                                        <div class="d-flex align-items-center ml-4 mt-2">
                                            <h4 class="">0</h4>
                                            <img src="@/assets/images/icons/ic_dashboard_arrow.svg" alt="" class="ml-1">
                                        </div>
                                        <div v-if="loading == true" style="height:215px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else class="d-flex align-items-center justify-content-center" style="height:215px">
                                            <p>No Data Available</p>
                                        </div>
                                        <!-- <apexchart type="area" height="200" :options="chartOptions" :series="seriesPlay"></apexchart> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contents text-left text-white mt-3">
                <h3 class="font-weight-normal">Contents</h3>
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-4">
                            <div class="card h-100 mr-3">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/icons/ic_dashboard_play.svg" alt="">
                                            <h5 class="text-none ml-1">Most Played</h5>
                                        </div>
                                        <div v-if="loading" style="height:180px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else>
                                            <div v-if="mostPlay.length > 0">
                                                <div v-for="data in mostPlay" :key="data" class="row align-items-center px-3 mt-3 text-white">
                                                    <div class="col-2">
                                                        <img v-if="data.images_cover != 'undefined'" :src=" data.images_cover" alt="" class="img-fluid">
                                                        <img v-else src="@/assets/images/default-cover.png" alt="" class="img-fluid">
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="ml-2">
                                                            <p class="mb-0 text-truncate">{{ data.quiz_name }}</p>
                                                            <div class="progress mt-1" style="height:4px;">
                                                                <div class="progress-bar bg-purple" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <small class="cursor-pointer text-purple">View Report</small>
                                                                <p class="mb-0">{{ data.total_play }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="h-200 d-flex align-items-center justify-content-center">
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card h-100 mr-3">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/icons/ic_dashboard_rate.svg" alt="">
                                            <h5 class="text-none ml-1">Most Rated</h5>
                                        </div>
                                        <div v-if="loading" style="height:180px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else>
                                            <div v-if="mostRated.length > 0">
                                                <div v-for="data in mostRated" :key="data" class="row align-items-center px-3 mt-3 text-white">
                                                    <div class="col-2">
                                                        <img v-if="data.images_cover != 'undefined'" :src=" data.images_cover" alt="" class="img-fluid">
                                                        <img v-else src="@/assets/images/default-cover.png" alt="" class="img-fluid">
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="ml-2">
                                                            <p class="mb-0 text-truncate">{{ data.quiz_name }}</p>
                                                            <div class="progress mt-1" style="height:4px;">
                                                                <div class="progress-bar bg-purple" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <small class="cursor-pointer text-purple">View Report</small>
                                                                <p class="mb-0">{{ data.rating }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="h-200 d-flex align-items-center justify-content-center">
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card h-100 mr-3">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/icons/ic_dashboard_duplicate.svg" alt="">
                                            <h5 class="text-none ml-1">Most Duplicated</h5>
                                        </div>
                                        <div v-if="loading" style="height:180px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else>
                                            <div v-if="mostDuplicated.length > 0">
                                                <div v-for="index in mostDuplicated" :key="index" class="row align-items-center px-3 mt-3 text-white">
                                                    <div class="col-2">
                                                        <img src="@/assets/images/background/cover_dashboard.png" alt="" class="img-fluid">
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="ml-2">
                                                            <p class="mb-0">Digital Marketing</p>
                                                            <div class="progress mt-1" style="height:4px;">
                                                                <div class="progress-bar bg-purple" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <small class="cursor-pointer text-purple">View Report</small>
                                                                <p class="mb-0">200</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="h-200 d-flex align-items-center justify-content-center">
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="audiences text-left text-white mt-3">
                <h3 class="font-weight-normal">Audiences</h3>
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-4">
                            <div class="card h-100 mr-3">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="d-flex align-items-center">
                                            <h5 class="text-none ml-1">Interests</h5>
                                        </div>
                                        <div v-if="loading" style="height:180px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else>
                                            <div v-if="interests.length > 0">
                                                <div v-for="index in interests" :key="index" class="row align-items-center px-3 mt-3 text-white">
                                                    <div class="col-12">
                                                        <div class="ml-2">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <p class="mb-1 mt-1">Bahasa Indonesia</p>
                                                                <p class="mb-0">50%</p>
                                                            </div>
                                                            <div class="progress mt-1" style="height:4px;">
                                                                <div class="progress-bar bg-purple" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="h-200 d-flex align-items-center justify-content-center">
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card h-100 mr-3">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="d-flex align-items-center">
                                            <h5 class="text-none ml-1">Locations</h5>
                                        </div>
                                        <div v-if="loading" style="height:180px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else>
                                            <div v-if="locations.length > 0">
                                                <div v-for="index in 3" :key="index" class="row align-items-center px-3 mt-3 text-white">
                                                    <div class="col-12">
                                                        <div class="ml-2">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <p class="mb-1 mt-1">Bandung</p>
                                                                <p class="mb-0">50%</p>
                                                            </div>
                                                            <div class="progress mt-1" style="height:4px;">
                                                                <div class="progress-bar bg-purple" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="h-200 d-flex align-items-center justify-content-center">
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card h-100 mr-3">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="d-flex align-items-center">
                                            <h5 class="text-none ml-1">Genders</h5>
                                        </div>
                                        <div v-if="loading" style="height:180px" class="d-flex justify-content-center align-items-center mb-3">
                                            <b-spinner label="Loading..."></b-spinner>
                                        </div>
                                        <div v-else>
                                            <div v-if="genders.length > 0">
                                                <div v-for="index in 2" :key="index" class="row align-items-center px-3 mt-3 text-white">
                                                    <div class="col-12">
                                                        <div class="ml-2">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <p class="mb-1 mt-1">{{ index == 1 ? 'Male' : 'Female' }}</p>
                                                                <p class="mb-0">50%</p>
                                                            </div>
                                                            <div class="progress mt-1" style="height:4px;">
                                                                <div class="progress-bar bg-purple" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="h-200 d-flex align-items-center justify-content-center">
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDashboard } from '@/services/dashboard/dashboard.service'
export default {
    data(){
        return {
            option_date: 7,
            loading:false,
            totalContent: null,
            totalPlay: null,
            mostPlay: [],
            mostRated: [],
            mostDuplicated: [],
            interests: [],
            locations: [],
            genders: [],
            data : [
                        [ new Date('1 Jan 2022 GMT').getTime(), 1 ],
                        [ new Date('2 Jan 2022 GMT').getTime(), 20 ],
                        [ new Date('3 Jan 2022 GMT').getTime(), 10 ] ,
                        [ new Date('4 Jan 2022 GMT').getTime(), 40 ],
                        [ new Date('5 Jan 2022 GMT').getTime(), 20 ]
                    ],
            seriesContent: [
                {
                    name: '',
                    data : [
                        [ '2022-01-11', 1 ],
                        [ '2022-01-12', 20 ],
                        [ '2022-01-13', 10 ] ,
                        [ '2022-01-14', 40 ],
                        [ '2022-01-15', 20 ]
                    ],

                },
            ],

            seriesPlay: [
                {
                    name: '',
                    data : [
                        [ '2022-01-11', 1 ],
                        [ '2022-01-12', 20 ],
                        [ '2022-01-13', 10 ] ,
                        [ '2022-01-14', 40 ],
                        [ '2022-01-15', 20 ]
                    ],

                },
            ],

            chartOptions: {
                chart: {
                    height: 200,
                    type: 'area',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    show: true,
                    borderColor: '#585859',
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors : '#fff'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors : [ '#fff' ]
                        }
                    }
                },
                tooltip: {
                    theme : 'dark',
                    x: {
                            format: 'dd MMM yyyy'
                        },
                    },
            },
        }
    },

    mounted(){
        var data = {
            option_date: this.option_date,
        }

        this.getDashboard(data)
    },

    methods:{
        generateDayWiseTimeSeries: function(baseval, count, yrange) {
            var i = 0;
            var series = [];
            while (i < count) {
                var x = baseval;
                var y =
                Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
                yrange.min;

                series.push([x, y]);
                baseval += 86400000;
                i++;
            }

            console.log(series);
            return series;
        },

        getDashboard(data){
            this.loading = true;
            this.seriesContent[0].data = []
            this.seriesPlay[0].data = []

            getDashboard(data)
            .then((response) => {
                if(response.status == true){
                    this.seriesContent[0].data = response.data.content_chart.content_data.reverse()
                    this.seriesPlay[0].data = response.data.play_chart.play_data.reverse()


                    this.totalContent = response.data.content_chart.total_content
                    this.totalPlay = response.data.play_chart.total_play

                    this.mostPlay = response.data.most_play
                    this.mostRated = response.data.most_rated
                    this.loading = false;
                }
            })
        },

        filterByDays(e){
            var data = {
                option_date: e.target.value,
            }
            this.getDashboard(data)
        },

        detailChart(val){
            if(val == '1'){
                this.$router.push({ name : 'Detail-Dashboard', query: { key : 'contents' } })
            } else if (val == '2'){
                this.$router.push({ name : 'Detail-Dashboard', query: { key : 'plays' } })
            } else if (val == '3'){
                this.$router.push({ name : 'Detail-Dashboard', query: { key : 'apd' } })
            } else if (val == '4'){
                this.$router.push({ name : 'Detail-Dashboard', query: { key : 'impressions' } })
            }
        }
    }
}
</script>

<style scoped>
.dashboard-inner{
    border-bottom: .1px solid #1C1C1C;
}

.dashboard-inner h3{
    font-size: 24px;
}

.nav-link {
    padding: 0.5rem 1rem !important;
    margin: 0.5rem 0.5rem !important;
}

.collections-tab .nav-tabs {
    border: none;
}

.collections-tab li button{
    margin: 2rem;
    padding: 0.625rem 2.5rem;
    border: 2px solid #8C8C8C;
    background-color: transparent;
    color: #8C8C8C;
    border-radius: 46px;
}

.collections-tab li .active {
    background-color: #6D75F6;
    color: white;
    border: 2px solid #6D75F6;
}

.bg-purple {
    background-color: #6D75F6
}

.text-purple{
    color: #6D75F6;
}

.text-none{
    color: #8C8C8C
}

.sorting-by .form-control{
    border-radius: 20px;
    background-color: #222222;
    border: 0;
    color: white;
    border-right: 13px solid #222222;
}

.h-200{
    height: 197px;
}

.qr--ac {
    gap: 14px;
}

.button-tr {
    padding: 0.425rem 2rem;
    border: 2px solid #2D2D2D;
    background-color: transparent;
    color: #8C8C8C;
    border-radius: 46px;
    transition: border 0.2s backgroun-color 0.25s;
}

.button-tr:hover {
    background-color: #6D75F6;
    color: white;
    border: 2px solid #6D75F6;
}
</style>