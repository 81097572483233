import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getDashboard(params) {
    return axios.get(ApiRoute.dashboard, {params: params})
    .then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message,
      }
      return data;
    }).catch((error) => {
      const data = {
        status: false,
        response: error
      }
      return data;
    })
  }